import Vue from 'vue'

import _ from 'lodash'

/**
 * @type {import('vuex').Module<any, any>}
 */
export const GroupPeopleHeaderModule = {
  namespaced: true,

  state: () => ({
    origin: 'groups',
    groupIDs: [],
    personID: null,
  }),

  getters: {
    OriginOption: ({ origin }) => origin,
    CurrentValueOption: ({ origin, groupIDs, personID }) => {
      const data = origin === 'groups' ? groupIDs : [personID].filter(Boolean)

      return {
        origin,
        data,
      }
    },

    MuralFilters: ({ origin, groupIDs, personID }) => {
      return {
        groupIDs:
          origin === 'groups'
            ? groupIDs.map(group => group.id).filter(Boolean)
            : [],
        personID: (origin === 'people' && personID?.id) || null,
      }
    },
  },

  actions: {
    setOrigin({ commit }, payload) {
      commit('SET_ORIGIN', payload)
    },
    setGroupPeople({ commit }, payload) {
      if (payload.origin === 'groups') {
        commit('SET_GROUP_IDS', payload.data)
      }

      if (payload.origin === 'people') {
        commit('SET_PERSON_ID', payload.data)
      }
    },
  },

  mutations: {
    SET_ORIGIN(state, payload) {
      if (_.isEqual(payload, state.origin)) return

      Vue.set(state, 'origin', payload)
    },
    SET_GROUP_IDS(state, payload) {
      if (_.isEqual(payload, state.groupIDs)) return
      Vue.set(state, 'groupIDs', payload)
    },
    SET_PERSON_ID(state, payload) {
      if (_.isEqual(payload, state.personID)) return
      Vue.set(state, 'personID', payload)
    },
  },
}
