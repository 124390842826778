<template>
  <LateralMenuItem
    data-test-mural-filters
    :is-mini="isMini"
    class="mural-filter-container"
    :title="$t('pageMural.lateralFilter.title')"
    icon="mdi-flash"
  >
    <template #header-action>
      <v-btn
        class="pa-1"
        text
        min-width="0"
        width="auto"
        height="auto"
        data-test-mural-filters-clean
        @click="cleanFilter()"
      >
        <span v-text="$t('LateralMenu.actions.clean')"></span>
      </v-btn>
    </template>

    <template #title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <ComposeForm
      ref="ComposeForm"
      class="filter"
      :form-name="'mural-filters'"
      :selectors="{ fields: _fields }"
      :storage-preferences="false"
      @compose:values="setFiltersValue"
    ></ComposeForm>
  </LateralMenuItem>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MuralFilters',
  props: {
    isMini: Boolean,
  },

  data() {
    return {
      fields: [
        {
          id: 'fromDate',
          _label: this.$t('pageMural.lateralFilter.dateFilter.label'),
          type: 'CustomDatePicker',

          CustomDatePicker: {
            range: true,
            inputConfig: {
              closeOnClick: true,
              rules: [],
            },
            menuConfig: {
              align: 'right',
              attach: false,
            },
            menuProps: {
              bottom: true,
              nudgeBottom: 12,
            },
          },
        },
      ],
    }
  },

  computed: {
    _fields() {
      const permissionedFields = {}

      return this.fields.filter(
        field => permissionedFields?.[field.id] !== false
      )
    },

    ...mapGetters({
      _LateralMenu: 'MuralFilters/LateralMenu',
    }),
  },

  methods: {
    setFiltersValue(values) {
      this.sendFilterValues(values)
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      Object.keys(filters).forEach(key => {
        if (['fromDate'].includes(key)) {
          filters['toDate'] = filters['fromDate']?.[1] || null
          filters[key] = filters[key]?.[0]
        }
      })

      this.$store.dispatch('MuralFilters/setFilters', filters)
    },

    cleanFilter() {
      if (!this.$refs.ComposeForm) return
      this.$refs.ComposeForm.resetComposeForm(true)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
