<template>
  <LateralMenu v-if="_LateralMenuRules && _LateralMuralFilters.length">
    <template #default="{ isMini }">
      <MuralFilters
        v-if="_LateralMuralFilters.includes('MuralFilters')"
        ref="MuralFilters"
        :is-mini="isMini"
      ></MuralFilters>
    </template>
  </LateralMenu>
</template>

<script>
import MuralFilters from '@/views/LateralMenu/Partials/MuralFilters/MuralFilters.vue'

export default {
  name: 'LateralMenuView',
  components: { MuralFilters },

  computed: {
    _LateralMuralFilters() {
      const Filters = {
        MuralFilters: true,
      }

      return Object.keys(Filters).filter(key => Filters[key])
    },
    _LateralMenuRules() {
      const hiddenRules = ['Login', 'NotFound']

      if (this.$route.matched.some(r => hiddenRules?.includes(r?.name))) {
        return false
      }

      return true
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
