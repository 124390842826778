import Vue from 'vue'

import _ from 'lodash'

/**
 * @type {import('vuex').Module<any, any>}
 */
export const MuralFiltersModule = {
  namespaced: true,

  state: () => ({
    LateralMenu: {},
  }),

  getters: {
    LateralMenu: ({ LateralMenu }) => LateralMenu,
  },

  actions: {
    setFilters({ commit }, payload) {
      commit('SET_LATERAL_MENU_FILTER', payload)
    },
    cleanFilters({ commit }) {
      commit('SET_LATERAL_MENU_FILTER', {})
    },
  },

  mutations: {
    SET_LATERAL_MENU_FILTER(state, payload) {
      if (_.isEqual(payload, state.LateralMenu)) return

      Vue.set(state, 'LateralMenu', payload)
    },
  },
}
